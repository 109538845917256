@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;
@use "../setting/loading" as *;

.foot-con {
  padding-left: 1rem;
  color: $color-backd;
  font-size: 0.8rem;

  &-top {
    position: fixed;
    left: 0;
    bottom: 0;
    color: white;

    @media (max-width: 768px) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &-project {
    @media (min-width: 769px) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media (max-width: 768px) {
    }
  }
  &-detail {
    @media (min-width: 769px) {
      position: fixed;
      left: 0;
      bottom: 0;
    }

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
  &-about {
    color: white;
    @media (min-width: 769px) {
      // position: absolute;
      left: 0;
      bottom: 0;
    }

    @media (max-width: 768px) {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: white;
    }
  }
  &-contact {
    @media (min-width: 769px) {
      position: fixed;
      left: 0;
      bottom: 0;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: white;
    }
  }
}
