@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;

.top-con {
  height: 94vh;
  color: white;

  display: flex;
  flex-direction: row;

  position: relative;

  &-left {
    width: 60%;
    padding: 2rem;
    @include flexColCenter();

    &-location {
      display: flex;
      margin-bottom: 1rem;
      .icon {
        font-size: 2rem;
      }
      h5 {
        padding-left: 1rem;
        margin: auto 0;
        text-shadow: 1px 1px 3px gray;
      }
    }
    &-me {
      &-active {
        display: flex;
        flex: row wrap;
        justify-content: space-between;
        margin-bottom: 1.5rem;

      

        h1 {
          font-family: $font-title;
          text-shadow: 1px 1px 3px gray;
        }
        h2 {
          text-align: center;
          padding: 0.3rem 0.5rem;
          font-size: 1.7rem;
          font-family: $font-sentence;
          background-color: $color-third-5;
          border-radius: 2.5rem;
        }
      }
      h5 {
        text-shadow: 1px 1px 3px gray;
      }
    }
    &-btn {
      padding-top: 2rem;

      a {
        text-decoration: none;
        color: $color-backl;
      }
    }
  }
  &-right {
    width: 35%;

    @include flexColCenter();
    align-items: center;

    &-pic {
      margin-left: 2rem;
      background-color: $color-second;
      width: 22rem;
      height: 22rem;
      position: relative;

      border-radius: 50%;
      @include alignFlexCenter;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;

        position: absolute;
        top: 1rem;
        left: 1rem;
      }
    }
  }
  &-attr {
    position: fixed;
    right: 0;
    bottom: 0;
    a {
      text-decoration: 0;
      color: white;
      font-size: 0.5rem;
    }
  }
}

/* Normal */
@media (min-width: 769px) and (max-width: 1200px) {
  .top-con {
    &-left {
      &-location {
        .icon {
        }
        h5 {
        }
      }
      &-me {
        &-active {
          @media (min-width: 769px) and (max-width: 1045px) {
            flex-direction: column;
          }
          h1 {
            font-family: $font-title;
          }
          h2 {
            padding: 0.3rem 1rem;
            font-size: 1.3rem;
            @media (min-width: 769px) and (max-width: 790px) {
              font-size: 1.1rem;
            }
            @include flexColCenter;


            @media (min-width: 769px) and (max-width: 1045px) {
              width: 80%;
            }

          }
        }
      }
      &-btn {
        font-size: 0.9rem;

        button {
          margin-bottom: 2rem;
        }
      }
    }
    &-right {
      width: 35%;
      &-pic {
        width: 19rem;
        height: 19rem;

        @media (min-width: 769px) and (max-width: 900px) {
          width: 17rem;
          height: 17rem;
        }
        img {
        }
      }
    }
    &-attr {
      a {
      }
    }
  }
}

/* tablet */
@media (min-width: 501px) and (max-width: 768px) {
  .top-con {
    &-left {
      width: 100%;
      &-location {
        .icon {
        }
        h5 {
        }
      }
      &-me {
        &-active {
          flex-direction: column;
          h1 {
          }
          h2 {
            padding: 0.15rem 0.3rem;
            font-size: 1.1rem;
            width: 55%;
            @media (min-width: 501px) and (max-width: 540px) {
              font-size: 1rem;
            }
          }
        }
      }
      &-btn {
        button {
        }
      }
    }
    &-right {
      width: 35%;

      position: absolute;
      right: 7%;
      top: 10%;

      @media (min-width: 501px) and (max-width: 600px) {
        top: 7.5%;
      }

      &-pic {
        width: 10rem;
        height: 10rem;
        img {
          top: 0.5rem;
          left: 0.5rem;
        }
      }
    }
    &-attr {
      a {
        font-size: 0.3rem;
      }
    }
  }
}

/* Smartphone L */
@media (min-width: 376px) and (max-width: 500px) {
  .top-con {
    &-left {
      width: 100%;
      &-location {
        .icon {
          font-size: 1.5rem;
        }
        h5 {
          padding-left: 0.5rem;
          font-size: 1rem;
        }
      }
      &-me {
        &-active {
          flex-direction: column;
          h1 {
            font-size: 1.7rem;
          }
          h2 {
            padding: 0.15rem 0.3rem;
            font-size: 1rem;
            width: 70%;
            @media (min-width: 376px) and (max-width: 405px) {
              width: 73%;
              font-size: 0.9rem;
            }
          }
        }
        h5 {
          font-size: 1.1rem;
        }
      }
      &-btn {
        flex-direction: row;
        justify-content: space-evenly;

        button {
        }
      }
    }
    &-right {
      width: 35%;

      position: absolute;
      right: 7%;
      top: 10%;
      @media (min-width: 376px) and (max-width: 390px) {
        top: 6%;
      }
      &-pic {
        width: 8rem;
        height: 8rem;

        img {
          top: 0.5rem;
          left: 0.5rem;
        }
      }
    }
    &-attr {
      a {
        font-size: 0.2rem;
      }
    }
  }
}

/* Smartphone S_M */
@media (max-width: 375px) {
  .top-con {
    &-left {
      width: 100%;

      &-location {
        .icon {
          font-size: 1.5rem;
        }
        h5 {
          padding-left: 0.5rem;

          font-size: 1rem;
        }
      }
      &-me {
        &-active {
          flex-direction: column;
          h1 {
            font-size: 1.7rem;
          }
          h2 {
            padding: 0.15rem 0.3rem;
            font-size: 0.8rem;
            width: 70%;
            @media (max-width: 375px) {
              font-size: 0.7rem;
              width: 75%;
            }
          }
        }
        h5 {
          font-size: 1rem;
        }
      }
      &-btn {
        flex-direction: row;
        justify-content: space-evenly;

        button {
        }
      }
    }
    &-right {
      width: 35%;

      position: absolute;
      right: 7%;
      top: 14%;

      @media (max-width: 350px) {
        top: 10%;
      }
      &-pic {
        width: 6rem;
        height: 6rem;
        img {
          top: 0.4rem;
          left: 0.4rem;
        }
      }
    }
    &-attr {
      a {
        font-size: 0.2rem;
      }
    }
  }
}
