@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;

.about-con {
  // height: 90vh;
  margin: 4vh;
  // /* Normal */
  // @media (min-width: 769px) {
  //   height: 92vh;
  // }

  &-bg {
    background-color: rgba(255, 255, 255, 0.6);

    padding: 1rem 0 1.5rem 0;
  }

  &-top {
    padding: 0.5rem 0 0 0;
    height: auto;
    &-resume {
      display: flex;
      flex-direction: row;
      justify-content: right;

      &-resumelink {
      }

      &-linkCont {
        @include alignFlexCenter;
        margin: auto 2rem;

        justify-content: space-around;

        padding: 0;

        li {
          list-style-type: none;
          &:first-child {
            margin-right: 2rem;
          }
          a {
            text-decoration: none;
            .sns-icon {
              font-size: 2.5rem;
              text-decoration: none;
            }
            .linkdin {
              color: #0177b5;
            }
            .github {
              color: #171515;
            }
          }
        }
      }
    }
  }
  &-below {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;

    &-pic {
      width: 25%;
      height: 100%;
      padding: 5rem;
      position: relative;

      &-absolute {
        width: 100%;

        &-picCont {
          display: block;
          position: absolute;
          @include aboutPicWidHei;

          & img {
            @include aboutPicWidHei;
            object-fit: cover;
            filter: grayscale(100%) contrast(120%);
            box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
            display: block;
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
            margin-top: -10px;
          }
          &:hover img {
            box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
          }
          & .glow-wrap {
            overflow: hidden;
            position: absolute;
            @include aboutPicWidHei;
            top: 0;
            margin-top: -10px;
          }
          & .glow {
            display: block;
            position: absolute;
            width: 40%;
            height: 200%;
            background: rgba(255, 255, 255, 0.2);
            top: 0;
            filter: blur(5px);
            transform: rotate(45deg) translate(-450%, 0);
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          &:hover .glow {
            transform: rotate(45deg) translate(450%, 0);
            transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          &:hover img,
          &:hover .glow-wrap {
            margin-top: 0;
          }

          h2 {
            font-family: $font-title;
            position: absolute;
            z-index: 1;
            transform: translate(-25%, -77%);
            margin: 0;
            line-height: 1.2;
          }
        }
      }
    }
    &-des {
      width: 75%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &-summary {
        padding: 1rem 2rem 0 2rem;

        a {
          text-decoration: none;
          color: $color-third;
        }
      }
      &-skill {
        padding: 1rem 2rem 0 2rem;
        h3 {
          font-family: $font-title;
          margin-bottom: 0;
        }
        &-con {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          &-con1 {
            width: 50%;
            padding: 1rem;
          }
          &-con2 {
            width: 50%;
            padding: 1rem;
          }
          .skill-logo {
            font-size: 3rem;
            padding: 0.5rem;
            background-color: rgba(255, 255, 255, 0.623);

            h4 {
              padding-bottom: 0.7rem;
            }

            .gridbox {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(5, 1fr);

              gap: 1rem;
              // justify-items: center;

              &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                  font-size: 1rem;
                }
              }
            }

            .ImHtmlFive {
              color: #e34c26;
            }
            .ImCss3 {
              color: #264de4;
            }
            .SiJavascript {
              color: #f0db4f;
            }
            .SiReact {
              color: #61dbfb;
            }
            .FaNodeJs {
              color: #8fc53f;
            }

            .IoLogoSass {
              color: #cd6799;
            }
            .SiTailwindcss {
              color: #43a6b3;
            }
            .SiBootstrap {
              color: #553c7b;
            }
            .SiRedux {
              color: #7348b6;
            }
            .SiExpress {
              color: black;
            }
          }
        }
      }
      &-education {
        padding: 1rem 2rem 0 2rem;
        h3 {
          font-family: $font-title;
          margin-bottom: 0.5rem;
        }
        &-con {
          width: 100%;
          .grid {
            display: grid;
            grid-template-columns: 10% 50% 40%;
            background-color: rgba(255, 255, 255, 0.623);
            padding: 1rem;

            a.item3 {
              cursor: pointer;
              text-decoration: none;
              color: black;
              p {
                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Normal(1) */
@media (min-width: 1054px) and (max-width: 1200px) {
  .about-con {
    height: 90vh;
    &-top {
      &-resume {
        display: flex;
        flex-direction: row;
        justify-content: right;

        &-resumelink {
        }

        &-linkCont {
          li {
            &:first-child {
            }
            a {
              .sns-icon {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
    &-below {
      &-pic {
        &-absolute {
          &-picCont {
            @include aboutPicWidHei;

            & img {
              @include aboutPicWidHei;
            }
            &:hover img {
            }
            & .glow-wrap {
              @include aboutPicWidHei;
            }

            h2 {
              font-size: 1.8rem;
            }
          }
        }
      }
      &-des {
        &-summary {
          h4 {
            font-size: 1.3rem;
          }
          p {
            font-size: 0.9rem;
          }
        }
        &-skill {
          // padding: 1rem 2rem 0 2rem;
          h3 {
            // margin-bottom: 0;
          }
          &-con {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-around;
            &-con1 {
              // width: 50%;
              padding: 0.5rem;
            }
            &-con2 {
              // width: 50%;
              padding: 0.5rem;
            }
            .skill-logo {
              font-size: 2.5rem;
              padding: 0.3rem;

              h4 {
                padding-bottom: 0.5rem;
              }

              .gridbox {
                // width: 100%;
                // display: grid;
                // grid-template-columns: repeat(5, 1fr);

                gap: 0.5rem;

                &-item {
                  // display: flex;
                  // flex-direction: column;
                  // align-items: center;
                  p {
                    font-size: 0.8rem;
                  }
                }
              }
            }
          }
        }
        &-education {
          h3 {
            margin-bottom: 0.7rem;
            font-size: 1.6rem;
          }
          &-con {
          }
        }
      }
    }
  }
}

/* Normal(2) */
@media (min-width: 769px) and (max-width: 1053px) {
  .about-con {
    height: 90vh;
    &-top {
      &-resume {
        &-resumelink {
        }
        &-linkCont {
          @include alignFlexCenter;
        }
      }
    }
    &-below {
      position: relative;

      &-pic {
        width: 35%;
        position: absolute;
        left: 0%;
        top: -10%;

        &-absolute {
          &-picCont {
            @include aboutPicWidHei;

            & img {
              @include aboutPicWidHei;
            }
            &:hover img {
            }
            & .glow-wrap {
              @include aboutPicWidHei;
            }

            h2 {
              // position: absolute;
              // z-index: 1;
              // transform: translate(-25%, -77%);
              // margin: 0;
              // line-height: 1.2;
              font-size: 1.8rem;
            }
          }
        }
      }
      &-des {
        width: 65%;
        height: auto;
        margin-left: 35%;

        &-summary {
          padding: 0.5rem 1rem 1 1rem;

          h4 {
            font-size: 1.3rem;
          }
          p {
            font-size: 0.9rem;
          }
        }

        &-skill {
          padding: 0 0 0 1rem;
          position: absolute;
          bottom: 0;
          left: 0;

          width: 35%;

          h3 {
            // margin-bottom: 0;
          }
          &-con {
            width: 100%;
            // display: flex;
            flex-direction: column;
            // justify-content: space-around;
            &-con1,
            &-con2 {
              width: 100%;
              padding: 0 0 0.3rem 0;
              .skill-logo {
                font-size: 2rem;
                // padding: 0;

                h4 {
                  padding-bottom: 0.5rem;
                }

                .gridbox {
                  // width: 100%;
                  // display: grid;
                  // grid-template-columns: repeat(5, 1fr);
                  @media (min-width: 769px) and (max-width: 909px) {
                    grid-template-columns: repeat(3, 1fr);
                  }
                  gap: 0.5rem;

                  &-item {
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    p {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        &-education {
          padding: 1rem 1rem 0 1rem;
          h3 {
            margin-bottom: 0.5rem;
            //     font-size: 1.3rem;
          }
          &-con {
            width: 100%;
            .item1 {
              grid-area: year1;
            }
            .item2 {
              grid-area: title1;
            }
            .item3 {
              grid-area: place1;
              text-align: right;
            }
            .item4 {
              grid-area: year2;
            }
            .item5 {
              grid-area: title2;
            }
            .item6 {
              grid-area: place2;
              text-align: right;
            }
            .item7 {
              grid-area: year3;
            }
            .item8 {
              grid-area: title3;
            }
            .item9 {
              grid-area: place3;
              text-align: right;
            }
            .item10 {
              grid-area: year4;
            }
            .item11 {
              grid-area: title4;
            }
            .item12 {
              grid-area: place4;
              text-align: right;
            }

            .grid {
              grid-template-areas:
                "year1 title1 title1 "
                "place1 place1 place1"
                "year2  title2 title2"
                "place2 place2 place2"
                "year3 title3 title3"
                "place3 place3 place3"
                "year4 title4 title4"
                "place4 place4 place4";

              h5 {
                font-size: 1rem;
              }
              p {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

/* tablet */
@media (min-width: 501px) and (max-width: 768px) {
  .about-con {
    height: auto;
    margin: 2vh;

    @media (min-width: 501px) and (max-width: 594px) {
      margin: 1.5vh;
    }

    &-top {
      height: auto;
      width: 100%;
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        font-family: $font-title;
        padding-left: 1rem;

        @media (min-width: 501px) and (max-width: 553px) {
          font-size: 1.6rem;
        }
      }
      &-resume {
        &-resumelink {
          margin-top: -0.3rem;
        }

        &-linkCont {
          margin: auto 1rem;

          li {
            &:first-child {
              margin-right: 1rem;
            }
            a {
              .sns-icon {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
    &-below {
      height: auto;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem 0 1rem 0;
      position: relative;
      &-pic {
        width: 35%;
        height: 100%;
        padding: 5rem;

        position: absolute;
        right: -3%;
        @media (min-width: 742px) and (max-width: 750px) {
          right: -2.5%;
        }
        @media (min-width: 722px) and (max-width: 741px) {
          right: -3%;
        }
        @media (min-width: 691px) and (max-width: 721px) {
          right: -4%;
        }
        @media (min-width: 656px) and (max-width: 690px) {
          right: -2.5%;
        }
        @media (min-width: 621px) and (max-width: 655px) {
          right: -1%;
        }
        @media (min-width: 595px) and (max-width: 620px) {
          right: 1%;
        }
        @media (min-width: 571px) and (max-width: 594px) {
          right: 9%;
          top: 44%;
        }
        @media (min-width: 540px) and (max-width: 570px) {
          right: 11%;
          top: 45%;
        }
        @media (min-width: 530px) and (max-width: 539px) {
          right: 13%;
          top: 46%;
        }
        @media (min-width: 501px) and (max-width: 529px) {
          right: 14%;
          top: 47%;
        }

        &-absolute {
          width: 100%;

          &-picCont {
            h2 {
              display: none;
            }

            @include aboutPicWidHei;

            & img {
              @include aboutPicWidHei;

              margin-top: -3px;
            }
            &:hover img {
            }
            & .glow-wrap {
              @include aboutPicWidHei;
            }

            h2 {
              font-size: 1.8rem;
            }
          }
        }
      }
      &-des {
        width: 100%;

        &-summary {
          padding: 0.5rem 1rem 0 1rem;
          width: 80%;

          @media (min-width: 501px) and (max-width: 594px) {
            width: 100%;
          }

          h4 {
            font-size: 1.1rem;
          }
          p {
            font-size: 0.8rem;
          }
        }

        &-skill {
          padding: 1rem 1rem 0 1rem;
          @media (min-width: 501px) and (max-width: 594px) {
            width: 65%;
          }
          h3 {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
          }
          &-con {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-around;
            @media (min-width: 501px) and (max-width: 594px) {
              flex-direction: column;
            }
            &-con1,
            &-con2 {
              // width: 50%;
              padding: 0.5rem;

              @media (min-width: 501px) and (max-width: 594px) {
                width: 100%;
              }

              .skill-logo {
                font-size: 2.3rem;
                padding: 0.3rem;

                h4 {
                  font-size: 1.1rem;
                  padding-bottom: 0;
                }

                .gridbox {
                  // width: 100%;
                  // display: grid;
                  // grid-template-columns: repeat(5, 1fr);

                  @media (min-width: 595px) and (max-width: 658px) {
                    grid-template-columns: repeat(3, 1fr);
                  }
                  @media (min-width: 524px) and (max-width: 594px) {
                    grid-template-columns: repeat(5, 1fr);
                  }
                  @media (min-width: 501px) and (max-width: 523px) {
                    grid-template-columns: repeat(3, 1fr);
                  }

                  gap: 0.5rem;

                  &-item {
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    p {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        &-education {
          padding: 1rem 1rem 0 1rem;
          h3 {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
          }
          &-con {
            width: 100%;
            .grid {
              // display: grid;
              grid-template-columns: 15% 50% 35%;
              // background-color: rgba(255, 255, 255, 0.623);
              h5 {
                font-size: 1rem;
              }

              p {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

/* Smartphone L */
@media (min-width: 376px) and (max-width: 500px) {
  .about-con {
    height: auto;
    margin: 2vh;

    &-top {
      height: 7%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (min-width: 376px) and (max-width: 430px) {
        flex-direction: column-reverse;
      }

      h1 {
        font-family: $font-title;
        font-size: 1.7rem;
        padding-left: 0.8rem;
        @media (min-width: 376px) and (max-width: 430px) {
          padding-top: 1rem;
        }
      }
      &-resume {
        display: flex;
        flex-direction: row;
        justify-content: right;

        &-resumelink {
          margin-top: 0.3rem;
        }

        &-linkCont {
          margin: auto 0.8rem;

          li {
            &:first-child {
              margin-right: 0.8rem;
            }
            a {
              .sns-icon {
                font-size: 1.7rem;
              }
            }
          }
        }
      }
    }
    &-below {
      height: auto;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      padding: 1rem 0;
      &-pic {
        position: absolute;
        right: 16%;
        top: 44%;
        @media (min-width: 431px) and (max-width: 475px) {
          right: 15%;
          top: 46%;
        }
        @media (min-width: 376px) and (max-width: 430px) {
          right: 14%;
          top: 43%;
        }

        &-absolute {
          &-picCont {
            h2 {
              display: none;
              // font-size: 1.4rem;
              // @media (min-width: 376px) and (max-width: 430px) {
              //   font-size: 1.2rem;
              // }
            }

            @include aboutPicWidHei;

            & img {
              @include aboutPicWidHei;
              margin-top: -3px;
              @media (min-width: 376px) and (max-width: 430px) {
                margin-top: -1px;
              }
            }
            &:hover img {
            }
            & .glow-wrap {
              @include aboutPicWidHei;
            }

            h2 {
              font-size: 1.8rem;
            }
          }
        }
      }
      &-des {
        width: 100%;

        &-summary {
          padding: 0.5rem 1rem 0 1rem;
          width: 100%;
          h4 {
            font-size: 1rem;
            @media (min-width: 376px) and (max-width: 430px) {
              font-size: 0.8rem;
            }
          }
          p {
            font-size: 0.7rem;
            @media (min-width: 376px) and (max-width: 430px) {
              font-size: 0.6rem;
            }
          }
        }
        &-skill {
          padding: 1rem 1rem 0 1rem;

          width: 65%;

          h3 {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
          }
          &-con {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-around;

            flex-direction: column;

            &-con1,
            &-con2 {
              // width: 50%;
              padding: 0.5rem;

              width: 100%;

              .skill-logo {
                font-size: 2.3rem;
                padding: 0.3rem;

                h4 {
                  font-size: 1.1rem;
                  padding-bottom: 0;
                }

                .gridbox {
                  // width: 100%;
                  // display: grid;
                  grid-template-columns: repeat(3, 1fr);

                  gap: 0.5rem;

                  &-item {
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    p {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        &-education {
          padding: 1rem 1rem 0 1rem;
          h3 {
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
          }
          &-con {
            width: 100%;
            .item1 {
              grid-area: year1;
            }
            .item2 {
              grid-area: title1;
            }
            .item3 {
              grid-area: place1;
              text-align: right;
            }
            .item4 {
              grid-area: year2;
            }
            .item5 {
              grid-area: title2;
            }
            .item6 {
              grid-area: place2;
              text-align: right;
            }
            .item7 {
              grid-area: year3;
            }
            .item8 {
              grid-area: title3;
            }
            .item9 {
              grid-area: place3;
              text-align: right;
            }
            .item10 {
              grid-area: year4;
            }
            .item11 {
              grid-area: title4;
            }
            .item12 {
              grid-area: place4;
              text-align: right;
            }

            .grid {
              grid-template-areas:
                "year1 title1 title1 "
                "place1 place1 place1"
                "year2  title2 title2"
                "place2 place2 place2"
                "year3 title3 title3"
                "place3 place3 place3"
                "year4 title4 title4"
                "place4 place4 place4";

              h5 {
                font-size: 0.8rem;
              }

              p {
                font-size: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}

/* Smartphone S_M */
@media (max-width: 375px) {
  .about-con {
    height: auto;
    margin: 2vh;

    &-top {
      height: 7%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      h1 {
        font-family: $font-title;
        padding-left: 1rem;
        padding-top: 1rem;
        font-size: 1.4rem;
      }
      &-resume {
        display: flex;
        flex-direction: row;
        justify-content: right;

        &-resumelink {
        }

        &-linkCont {
          margin: auto 0.8rem;

          li {
            &:first-child {
              margin-right: 0.8rem;
            }
            a {
              .sns-icon {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
    &-below {
      flex-direction: column;
      height: auto;
      position: relative;
      &-pic {
        width: 35%;
        height: 100%;
        padding: 5rem;

        position: absolute;
        left: -10%;
        top: -13%;

        &-absolute {
          width: 100%;

          &-picCont {
            h2 {
              // font-size: 1.4rem;
              display: none;
            }

            @include aboutPicWidHei;

            & img {
              @include aboutPicWidHei;

              margin-top: -3px;

              display: none;
            }
            &:hover img {
            }
            & .glow-wrap {
              @include aboutPicWidHei;
            }

            h2 {
              font-size: 1.8rem;
            }
          }
        }
      }
      &-des {
        width: 100%;
        justify-content: space-between;
        padding: 0 0 1rem 0;
        &-summary {
          padding: 1rem 1rem 0 1rem;

          width: 100%;

          h4 {
            font-size: 0.8rem;
          }
          p {
            font-size: 0.6rem;
          }
        }

        &-skill {
          padding: 1rem 1rem 0 1rem;

          width: 100%;

          h3 {
            margin-bottom: 0;
            font-size: 0.8rem;
          }
          &-con {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-around;

            flex-direction: column;

            &-con1,
            &-con2 {
              // width: 50%;
              padding: 0.5rem;

              width: 100%;

              .skill-logo {
                font-size: 2rem;
                padding: 0.3rem;

                h4 {
                  font-size: 0.7rem;
                  padding-bottom: 0;
                }

                .gridbox {
                  // width: 100%;
                  // display: grid;
                  grid-template-columns: repeat(5, 1fr);

                  gap: 0.5rem;

                  &-item {
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    p {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        &-education {
          padding: 1rem 1rem 0 1rem;
          h3 {
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
          }
          &-con {
            width: 100%;
            .item1 {
              grid-area: year1;
            }
            .item2 {
              grid-area: title1;
            }
            .item3 {
              grid-area: place1;
              text-align: right;
            }
            .item4 {
              grid-area: year2;
            }
            .item5 {
              grid-area: title2;
            }
            .item6 {
              grid-area: place2;
              text-align: right;
            }
            .item7 {
              grid-area: year3;
            }
            .item8 {
              grid-area: title3;
            }
            .item9 {
              grid-area: place3;
              text-align: right;
            }
            .item10 {
              grid-area: year4;
            }
            .item11 {
              grid-area: title4;
            }
            .item12 {
              grid-area: place4;
              text-align: right;
            }

            .grid {
              grid-template-areas:
                "year1 title1 title1 "
                "place1 place1 place1"
                "year2  title2 title2"
                "place2 place2 place2"
                "year3 title3 title3"
                "place3 place3 place3"
                "year4 title4 title4"
                "place4 place4 place4";

              h5 {
                font-size: 0.8rem;
              }

              p {
                font-size: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}
