@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;
@use "../setting/tag" as *;
@use "../setting/loading" as *;

.project-con {
  height: 92vh;
  width: 100%;
  color: white;
  text-align: center;

  display: flex;
  flex-direction: row;

  &-loading-logo {
    @include loading-logo;
  }

  &-left {
    width: 60%;

    @include flexColCenter();

    &-comp {
      height: 70%;
      width: 90%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &-modal {
        background-color: $color-backd-8;

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        padding: 2rem;

        @include flexColCenter;
        h2 {
          font-family: $font-title;
          margin-bottom: 1rem;
        }

        &-tag {
          @include tag();
        }
        &-deslink-con {
          width: 100%;
          display: flex;
          flex-direction: row;

          &-description {
            width: 80%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  &-right {
    width: 40%;

    @include flexColCenter();

    &-list {
      margin-right: 30%;
      text-align: left;
      background-color: rgba(255, 255, 255, 0.473);
      padding: 2rem;

      &-each {
        margin-top: 2rem;
        width: 100%;

        a {
          text-decoration: none;
          color: white;
          text-shadow: 0.1rem 0.1rem 0.6rem $color-second;
          font-size: 2rem;
          height: 2rem;
          transition: 0.5s;
          position: relative;
        }
        a::after {
          content: "\00bb";
          position: absolute;
          opacity: 0;
          padding-left: 2rem;
          transition: 0.5s;
        }
        hr {
          width: 30%;
          height: 0.2rem;
          margin-top: -0.3rem;
          transition: 0.5s;
        }

        &:hover {
          a::after {
            opacity: 1;
          }
          hr {
            width: 60%;
          }
        }
      }
    }
  }
}
/* Normal */
@media (min-width: 769px) and (max-width: 1200px) {
  .project-con {
    &-left {
      width: 65%;
      &-comp {
        img {
        }
        &-modal {
          h2 {
          }

          &-tag {
          }
          &-deslink-con {
            &-description {
            }
          }
        }
      }
    }
    &-right {
      width: 35%;

      &-list {
        margin-right: 10%;
        padding: 1rem;

        &-each {
          margin-top: 1.5rem;
          a {
            font-size: 1.6rem;
            height: 2rem;
          }
          a::after {
            padding-left: 0.6rem;
          }
          hr {
            width: 30%;
            height: 0.2rem;
            margin-top: -0.3rem;
            transition: 0.5s;
          }

          &:hover {
            a::after {
              opacity: 1;
            }
            hr {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

// window < 768
/* tablet */
@media (max-width: 768px) {
  .project-con {
    height: 94vh;
    padding: 2rem;

    display: flex;
    flex-direction: column;

    .mobile-project {
      text-decoration: none;

      &-con {
        width: 80%;
        padding: 2rem;
        margin: 0 auto 1rem auto;

        background-color: rgba(255, 255, 255, 0.6);
        color: black;

        h1 {
          font-family: $font-title;
        }

        &-tag {
          width: 100%;
          margin: 0 auto 0.5rem auto;
          @include tag();
        }

        &-pic {
          margin-top: 0.5rem;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

/* Smartphone L */
@media (min-width: 376px) and (max-width: 500px) {
  .project-con {
    padding: 1rem;

    .mobile-project {
      &-con {
        width: 90%;
        padding: 1rem;
        margin: 0 auto 1rem auto;

        h1 {
        }

        &-tag {
          width: 100%;
          @include tag();
        }

        &-pic {
          img {
          }
        }
      }
    }
  }
}

/* Smartphone S_M */
@media (max-width: 375px) {
  .project-con {
    padding: 0.5rem;

    .mobile-project {
      &-con {
        width: 90%;
        padding: 1rem;
        margin: 0 auto 1rem auto;

        h1 {
        }

        &-tag {
          width: 100%;
          @include tag();
        }

        &-pic {
          img {
          }
        }
      }
    }
  }
}
