@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;

.btn {
  font-size: 1.2rem;
  font-weight: 200;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  user-select: none;
  -webkit-user-select: none;

  @media (min-width: 769px) and (max-width: 850px) {
    font-size: 0.9rem;
  }

  margin-right: 2rem;

  &:after {
    content: "";
    width: 100%;
    height: 100%;

    position: absolute;
    top: 7px;
    left: 7px;
    transition: 0.2s;

    opacity: 0.5;
  }

  &:hover:after {
    top: 0px;
    left: 0px;
  }
}

.btn-top {
  border: 1px solid white;
  a {
    color: black;
  }

  &:after {
    background-color: $color-third;
  }
  &:hover {
    border: none;

    opacity: 0.8;

    a {
      color: white;
    }
  }
}

.btn-resume {
  border: 1px solid white;
  opacity: 0.8;
  a {
    color: black;
  }
  &:after {
    background-color: $color-main;
  }
  &:hover {
    border: none;

    color: white;
  }
}

.btn-submit {
  border: 1px solid $color-third;
  &:hover {
    color: white;
    border: 1px solid $color-third;
  }
}

@media (min-width: 768px) {
  .btn {
    padding: 13px 50px 13px;
  }
}
