@charset "UTF-8";

@use "page/nav";
@use "page/toppage";
@use "page/projectpage";
@use "page/projectdetail";
@use "page/about";
@use "page/contact";
@use "page/footer";

@use "setting/color" as *;
@use "setting/font" as *;
@use "setting/mixin" as *;
@use "setting/button" as *;
@use "setting/tag" as *;
@use "setting/loading" as *;

//react-image-gallery
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.\? {
  box-shadow: inset 4px 4px rgb(236, 15, 170), inset -4px -4px rgb(236, 15, 170);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
p {
  margin: 0;
  padding: 0;
  font-family: $font-sentence;
}

html,
body {
  height: 100%;
  width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.app-con {
  // height: 100vh;
  // height: auto;

  // width: 100%;
  // position: fixed;

  width: 100vw;
  height: 100vh;
  min-height: 100%;
  box-sizing: border-box;
  // overflow-x: hidden;
  // overflow-y: hidden;

  /* Normal */
  @media (max-width: 769px) {
    height: 100vh;
  }

  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;

  //   // position: relative;
}

.app-con-background-top {
  background: url("../image/background-top.png") no-repeat right center;
  background-size: contain;
  background-color: $color-main;
  background-position: right 0 bottom 0;

  @media (max-width: 500px) {
  }
}
.app-con-background-project {
  @include backgroundImage(url("../image/background-project.jpg"));
  background-position: right 0 bottom 0;
}
.app-con-background-about {
  @include backgroundImage(url("../image/background-about.jpg"));
  background-position: right 0 bottom 0;
}
.app-con-background-contact {
  @include backgroundImage(url("../image/background-contact.jpg"));
  background-position: right 0 bottom 0;
}
