
$color-main: #ebc697;
$color-second: #BF5D6C;
$color-third: #7D9C93;
$color-backl: #D7D1C3;
$color-backd: #A9B0B1;

$color-main-5: #ebc79785;
$color-second-5: #bf5d6c85;
$color-third-5: #7d9c9386;
$color-backl-5: #d7d1c38e;
$color-backl-8: #d7d1c3c9;
$color-backd-5: #a9b0b18e;
$color-backd-8: #a9b0b1c9;

