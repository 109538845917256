@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;
@use "../setting/tag" as *;

.project-detail {
  width: 100%;
  display: flex;
  height: auto;
  background-color: white;
  color: black;
  text-align: left;

  &-left {
    width: 60%;
    padding: 5rem 2rem 5rem 5rem;
    @include flexColCenter;
  }

  &-right {
    width: 40%;
    padding: 5rem 5rem 5rem 2rem;
    position: relative;

    .backLink {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;

      text-decoration: none;
      color: #777;

      &:hover {
        color: $color-third;
      }
    }

    &-titleLink {
      width: 100%;

      display: flex;
      flex-direction: row;

      h1 {
        font-family: $font-title;
        width: 75%;
      }
      h1:after {
        content: "";
        display: block;
        border-bottom: $color-backd 0.1rem solid;
        width: 4rem;
      }

      &-link {
        width: 25%;
        @include flexColCenter;

        ul {
          width: 100%;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          li {
            list-style-type: none;

            a {
              text-decoration: none;

              .sns-icon {
                font-size: 2.5rem;
                text-decoration: none;
              }
              .earth {
                color: #72b3d6;
              }
              .github {
                color: #171515;
              }
            }
          }
        }
      }
    }

    &-tag {
      margin: 1rem auto;
      @include tag();
    }

    &-accordion {
      padding: 2rem 0;

      .accordion {
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
      }

      .active,
      .accordion:hover {
        background-color: #ccc;
      }
      .accordion:after {
        content: "\02795"; /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        color: #777;
        float: right;
        margin-left: 5px;
      }

      .active:after {
        content: "\2796"; /* Unicode character for "minus" sign (-) */
      }

      /* Style the accordion panel. Note: hidden by default */
      .panel {
        padding: 1rem;
        background-color: white;
        // display: none;
        // max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
    }
  }
}

/* Normal */
@media (min-width: 769px) and (max-width: 1200px) {
  .project-detail {
    &-left {
      padding: 5rem 1rem 5rem 3rem;
    }

    &-right {
      padding: 5rem 1.5rem 5rem 1rem;

      &-titleLink {
        width: 100%;

        h1 {
          font-size: 2rem;
          @media (min-width: 769px) and (max-width: 1000px) {
            font-size: 1.5rem;
          }
        }
        h1:after {
        }

        &-link {
          width: 25%;
          ul {
            li {
              a {
                .sns-icon {
                  @media (min-width: 769px) and (max-width: 1000px) {
                    font-size: 2rem;
                  }
                }
              }
            }
          }
        }
      }
      h4 {
        font-size: 1.3rem;
        @media (min-width: 769px) and (max-width: 1000px) {
          font-size: 1rem;
        }
      }

      &-tag {
        margin: 1rem auto;
        @include tag();
      }

      p {
        font-size: 1rem;
        @media (min-width: 769px) and (max-width: 1000px) {
          font-size: 0.8rem;
        }
      }

      &-accordion {
        padding-top: 3rem;

        .accordion {
          p {
            font-size: 1rem;
            @media (min-width: 769px) and (max-width: 1000px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

/* tablet */
@media (max-width: 768px) {
  .project-detail {
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-right {
      width: 100%;
      padding: 2rem 1.5rem 0 1rem;
      position: relative;

      .backLink {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;

        text-decoration: none;
        color: #777;

        &:hover {
          color: $color-third;
        }
      }

      &-titleLink {
        width: 100%;

        h1 {
        }
        h1:after {
        }

        &-link {
          width: 25%;
          ul {
            li {
              a {
                .sns-icon {
                }
              }
            }
          }
        }
      }

      &-tag {
        margin: 1rem auto;
        @include tag();
      }

      &-desc {
        width: 90%;
        margin: 0 auto;
      }

      &-img {
        width: 70%;
        margin: 1rem auto;
      }

      &-accordion {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
      }
    }
  }
}

/* Smartphone L */
@media (min-width: 376px) and (max-width: 500px) {
  .project-detail {
    height: auto;

    &-right {
      padding: 2rem 1rem 0 1rem;

      &-titleLink {
        h1 {
          font-size: 1.5rem;
        }
        h1:after {
        }

        &-link {
          width: 25%;

          ul {
            li {
              a {
                .sns-icon {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
      h4 {
        font-size: 1.1rem;
      }

      &-tag {
        margin: 1rem auto;
        @include tag();
      }

      &-desc {
        width: 95%;
        margin: 0 auto;
      }

      &-img {
        width: 90%;
        margin: 1rem auto;
      }

      &-accordion {
        padding-top: 1rem;
        padding-bottom: 2rem;
      }
    }
  }
}

/* Smartphone S_M */
@media (max-width: 375px) {
  .project-detail {
    height: auto;

    &-right {
      padding: 2rem 1rem 0 1rem;

      &-titleLink {
        h1 {
          font-size: 1.5rem;
        }
        h1:after {
        }

        &-link {
          width: 25%;

          ul {
            li {
              a {
                .sns-icon {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
      h4 {
        font-size: 1.1rem;
      }

      &-tag {
        margin: 1rem auto;
        @include tag();
      }

      &-desc {
        width: 95%;
        margin: 0 auto;
      }

      &-img {
        width: 90%;
        margin: 1rem auto;
      }

      &-accordion {
        padding-top: 1rem;
        padding-bottom: 2rem;
      }
    }
  }
}
