@use "../setting/color" as *;
@use "../setting/font" as *;
@use "../setting/mixin" as *;
@use "../setting/loading" as *;

.contact-con {
  height: 84vh;
  margin: 4vh;
  color: black;
  display: flex;

  &-loading-logo {
    @include loading-logo;
  }

  &-form {
    width: 60%;
    background-color: $color-backl-5;
    margin-left: 5%;
    padding: 2rem 3rem;

    &-center {
      @include flexColCenter;

      h3 {
        font-family: $font-title;
        padding: 1rem 0;
        margin-bottom: 1rem;
      }

      .FormGroup {
        margin-bottom: 3rem;
      }

      &-contactForm {
        font-size: 1.2rem;

        .form-group {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &-social {
    width: 30%;
    height: 100%;
    padding: 3rem 3rem;

    &-comment {
      margin-bottom: 3rem;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 1rem;

      h3 {
        font-family: $font-title;
        margin-bottom: 2rem;
        font-size: 1.4rem;
      }

      h5 {
        font-size: 1.2rem;
      }
    }

    &-links {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 1rem;
      h3 {
        font-family: $font-title;
        font-size: 1.4rem;
      }
      ul {
        width: 100%;
        margin: 2rem auto 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        li {
          list-style-type: none;
          a {
            text-decoration: none;
            .sns-icon {
              font-size: 2.5rem;
              text-decoration: none;
            }
            .linkdin {
              color: #0177b5;
            }
            .github {
              color: #171515;
            }
          }
        }
      }
    }
  }
}

/* Normal */
@media (min-width: 769px) and (max-width: 1380px) {
  .contact-con {
    @media (min-width: 769px) and (max-width: 1040px) {
      margin: 4vh auto;
    }

    &-form {
      width: 65%;
      margin-left: 2.5%;

      &-center {
        h3 {
        }

        .FormGroup {
          margin-bottom: 3rem;
        }

        &-contactForm {
          .form-group {
          }
        }
      }
    }

    &-social {
      width: 35%;
      padding: 2rem 1rem;
      &-comment {
        h3 {
          font-size: 1.2rem;
          @media (min-width: 769px) and (max-width: 870px) {
            font-size: 1rem;
          }
        }
        h5 {
          font-size: 1rem;
          @media (min-width: 769px) and (max-width: 870px) {
            font-size: 0.8rem;
          }
        }
      }

      &-links {
        h3 {
          font-size: 1.2rem;

          @media (min-width: 769px) and (max-width: 870px) {
            font-size: 1rem;
          }
        }
        ul {
          li {
            a {
              .sns-icon {
              }
            }
          }
        }
      }
    }
  }
}

/* tablet */
@media (min-width: 501px) and (max-width: 768px) {
  .contact-con {
    height: auto;
    margin: 2vh;
    display: flex;
    flex-direction: column;
    &-form {
      width: 90%;
      margin-left: 5%;

      &-center {
        h3 {
          margin-bottom: 0;
        }

        .FormGroup {
          margin-bottom: 1.5rem;
        }

        &-contactForm {
          .form-group {
            margin-bottom: 0;
          }
        }
      }
    }

    &-social {
      width: 80%;
      height: auto;
      padding: 0.7rem 0.5rem;
      margin: 0 auto;

      display: flex;
      flex-direction: column;

      &-comment {
        margin-bottom: 0;
        width: 100%;
        h3 {
          font-size: 1.2rem;
        }
        h5 {
          font-size: 1rem;
        }
      }

      &-links {
        width: 100%;
        height: 100%;
        h3 {
          font-size: 1.2rem;
        }
        ul {
          margin: 0;
          align-items: center;
          padding: 0;

          flex-direction: row;
          li {
            a {
              .sns-icon {
                font-size: 2.5rem;
                margin: 1rem auto;
              }
            }
          }
        }
      }
    }
  }
}

/* Smartphone */
@media (max-width: 500px) {
  .contact-con {
    height: auto;
    margin: 2vh;
    display: flex;
    flex-direction: column;

    &-form {
      width: 90%;
      margin-left: 5%;
      &-center {
        h3 {
          margin-bottom: 0;
        }

        .FormGroup {
          margin-bottom: 1.5rem;
        }

        &-contactForm {
          .form-group {
            margin-bottom: 0;
          }
        }
      }
    }

    &-social {
      width: 100%;
      height: 100%;
      padding: 0.7rem 0.5rem;
      display: flex;
      flex-direction: column;

      &-comment {
        margin-bottom: 0;
        width: 100%;

        h3 {
          font-size: 1.2rem;
        }
        h5 {
          font-size: 1rem;
        }
      }

      &-links {
        width: 100%;
        height: 100%;
        h3 {
          font-size: 1.2rem;
        }
        ul {
          margin: 0;
          align-items: center;
          padding: 0;

          flex-direction: row;
          li {
            a {
              .sns-icon {
                font-size: 2.5rem;
                margin: 1rem auto;
              }
            }
          }
        }
      }
    }
  }
}
